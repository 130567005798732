<!--
 * @Author: zhangjiaheng
 * @Date: 2020-06-10 16:19:24
 * @LastEditTime: 2024-01-23 18:06:20
 * @LastEditors: Please set LastEditors
 * @Description: 我的事项
 * @FilePath: \user\src\views\home\myEn\en.vue
-->
<template>
  <div class="box">
    <!-- <myTask
      style="margin-bottom:10px;height:500px"
    ></myTask> -->
    <myBuss
      style="margin-bottom:10px;height:500px"
    ></myBuss>
    <!-- <myOrder
      style="margin-bottom:10px;height:500px"
    ></myOrder> -->
    <myRead
      style="margin-bottom:10px;height:580px"
    ></myRead>
    <handle
      style="margin-bottom:10px;height:500px"
    ></handle>
  </div>
</template>

<script>
// import myTask from "./components/myTask";
import myBuss from "./components/myBuss";
// import myOrder from "./components/myOrder";
import myRead from "./components/myRead";
import handle from "./components/handle";

export default {
  components: {
    myBuss, myRead, handle
  }
};
</script>

<style lang="scss" scoped>
.box {
  overflow-y: auto;
  // background: #232c3d;
}
.box::-webkit-scrollbar {
  display: none;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-enlis" },
    [
      _c("div", { staticClass: "ybox-mod enbuss-mod" }, [
        _c("div", { staticClass: "ybox-title" }, [
          _c(
            "div",
            { staticClass: "pull-left" },
            [
              _c("en-icon", {
                staticStyle: {
                  "vertical-align": "middle",
                  cursor: "pointer",
                  "margin-left": "0px",
                },
                attrs: { name: "fanhui", size: "small" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.callback.apply(null, arguments)
                  },
                },
              }),
              _vm.myNotice.total
                ? _c("span", [_vm._v(_vm._s(_vm.message()))])
                : _c("span", [_vm._v("回执列表")]),
              _c("en-select", {
                staticStyle: {
                  display: "inline-block",
                  width: "140px",
                  "margin-left": "5px",
                },
                attrs: { placeholder: "请选择", data: _vm.options, width: 140 },
                on: { change: _vm.change },
                model: {
                  value: _vm.receiptFlg,
                  callback: function ($$v) {
                    _vm.receiptFlg = $$v
                  },
                  expression: "receiptFlg",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pull-right" },
            [
              _c(
                "en-image-setting",
                {
                  staticClass: "piliangchuli-weidu",
                  attrs: {
                    name: "yijianhuizhi",
                    size: "small",
                    fill: "#26C393",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.receipt()
                    },
                  },
                },
                [_vm._v(" 一键回执 ")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "en-loading",
                rawName: "v-en-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "ybox-content",
          },
          [
            !_vm.noData
              ? _c(
                  "div",
                  {
                    staticClass: "enlis enlis-buss",
                    attrs: { id: "receipt-enlis" },
                  },
                  _vm._l(_vm.noticeList, function (item, k) {
                    return _c(
                      "div",
                      {
                        key: k,
                        staticClass: "mod",
                        class: item.receipt === "1" ? "receipt" : "",
                        on: {
                          click: function ($event) {
                            return _vm.modClick(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col col-icon",
                            class: item.readFlag === "000" ? "msg-remind" : "",
                          },
                          [
                            _c("p", [
                              _c(
                                "span",
                                { staticClass: "iconfont" },
                                [
                                  _c("en-icon", {
                                    attrs: { name: "xiaoxi", size: "small" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "pull-left" }, [
                          _c("div", { staticClass: "col col-num" }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  item["templateName"] || item["name"] || ""
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col col-tit" }, [
                            _c("p", [_vm._v(_vm._s(item["message"] || ""))]),
                          ]),
                        ]),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "col col-time" }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.getDateFormat(item["createTime"]))
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col col-operate" }, [
                            _c(
                              "span",
                              {
                                staticClass: "span-btn",
                                attrs: { title: "回执" },
                              },
                              [
                                item.receipt === "0"
                                  ? _c("en-icon", {
                                      staticStyle: {
                                        color: "#4fd2c2",
                                        opacity: "1",
                                      },
                                      attrs: { name: "huizhi", size: "20" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.receipt(item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "span-btn",
                                attrs: { title: "稍后提醒" },
                              },
                              [
                                _c("en-icon", {
                                  staticStyle: {
                                    color: "#fd9a4a",
                                    "margin-left": "16px",
                                    opacity: "1",
                                  },
                                  attrs: { name: "shaohoutixing", size: "20" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.remind(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "span-btn",
                                attrs: { title: "删除" },
                              },
                              [
                                item.receipt !== "0"
                                  ? _c("en-icon", {
                                      staticStyle: {
                                        color: "#f54646",
                                        "margin-left": "16px",
                                        opacity: "1",
                                      },
                                      attrs: { name: "shibai", size: "20" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteNotice(item, k)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.noData
              ? _c("en-result", { attrs: { type: "NoData" } })
              : _vm._e(),
            _vm.myNotice.hasNext
              ? _c("viewAddMore", {
                  attrs: {
                    total: _vm.myNotice.total,
                    pageNo: _vm.pageNo,
                    pageSize: _vm.pageSize,
                  },
                  on: { addMore: _vm.addMore },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.noticeType.remind.flg
        ? _c("viewRemind", {
            attrs: {
              show: _vm.noticeType.remind.flg,
              content: _vm.noticeType.remind.content,
            },
            on: {
              toShow: function ($event) {
                return _vm.toget("remind")
              },
            },
          })
        : _vm._e(),
      _c("viewBacktop", {
        staticClass: "back-top",
        attrs: { elementId: "receipt-enlis" },
      }),
      _vm.noticeType.btnOperation.flg
        ? _c("viewBtnOperation", {
            attrs: {
              show: _vm.noticeType.btnOperation.flg,
              content: _vm.noticeType.btnOperation.content,
            },
            on: {
              toShow: function ($event) {
                return _vm.toget("btnOperation")
              },
              viewDetails: function ($event) {
                return _vm.messageOperation(_vm.noticeType.btnOperation.content)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("myBuss", {
        staticStyle: { "margin-bottom": "10px", height: "500px" },
      }),
      _c("myRead", {
        staticStyle: { "margin-bottom": "10px", height: "580px" },
      }),
      _c("handle", {
        staticStyle: { "margin-bottom": "10px", height: "500px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
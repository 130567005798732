<template>
  <!--回执列表 只会从消息中心跳转过来 会有返回按钮-->
  <div class="my-enlis">
    <div class="ybox-mod enbuss-mod">
      <div class="ybox-title">
        <div class="pull-left">
          <en-icon name="fanhui" size="small" style="vertical-align:middle;cursor: pointer;margin-left: 0px;" @click.native="callback"> </en-icon>
          <span v-if="myNotice.total">{{ message() }}</span>
          <span v-else>回执列表</span>
          <en-select v-model="receiptFlg" placeholder="请选择" :data="options" :width="140" style="display: inline-block;width: 140px;margin-left:5px;" @change="change"></en-select>
        </div>
        <div class="pull-right">
          <en-image-setting class="piliangchuli-weidu" name="yijianhuizhi" size="small" fill="#26C393" @click.native="receipt()">
            一键回执
          </en-image-setting>
        </div>
      </div>
      <div class="ybox-content" v-en-loading="loading">
        <div class="enlis enlis-buss" v-if="!noData" id="receipt-enlis">
          <div v-for="(item, k) in noticeList" :key="k" class="mod" :class="item.receipt === '1' ? 'receipt' : ''" @click="modClick(item)">
            <div class="col col-icon" :class="item.readFlag === '000' ? 'msg-remind' : ''">
              <p>
                <span class="iconfont">
                  <en-icon name="xiaoxi" size="small"></en-icon>
                </span>
              </p>
            </div>
            <div class="pull-left">
              <div class="col col-num">
                <p>{{ item["templateName"] || item["name"] || "" }}</p>
              </div>
              <div class="col col-tit">
                <p>{{ item["message"] || "" }}</p>
              </div>
            </div>
            <div class="pull-right">
              <div class="col col-time">
                <p>{{ getDateFormat(item["createTime"]) }}</p>
              </div>
              <div class="col col-operate">
                <span class="span-btn" title="回执">
                  <en-icon v-if="item.receipt === '0'" name="huizhi" size="20" style="color:#4fd2c2;opacity: 1;" @click.native.stop="receipt(item)"></en-icon>
                </span>
                <span class="span-btn" title="稍后提醒">
                  <en-icon name="shaohoutixing" size="20" style="color:#fd9a4a;margin-left:16px;opacity: 1;" @click.native.stop="remind(item)"></en-icon>
                </span>
                <span class="span-btn" title="删除">
                  <en-icon v-if="item.receipt !== '0'" name="shibai" size="20" style="color:#f54646;margin-left:16px;opacity: 1;" @click.native.stop="deleteNotice(item, k)"></en-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
        <en-result v-if="noData" type="NoData"></en-result>
        <!-- 加载更多 -->
        <viewAddMore v-if="myNotice.hasNext" :total="myNotice.total" :pageNo="pageNo" :pageSize="pageSize" @addMore="addMore"></viewAddMore>
      </div>
    </div>
    <viewRemind v-if="noticeType.remind.flg" :show="noticeType.remind.flg" :content="noticeType.remind.content" @toShow="toget('remind')"></viewRemind>
    <viewBacktop class="back-top" elementId="receipt-enlis"></viewBacktop>
    <!-- 带按钮的操作弹框 -->
    <viewBtnOperation
      v-if="noticeType.btnOperation.flg"
      :show="noticeType.btnOperation.flg"
      :content="noticeType.btnOperation.content"
      @toShow="toget('btnOperation')"
      @viewDetails="messageOperation(noticeType.btnOperation.content)"
    ></viewBtnOperation>
  </div>
</template>

<script>
import { enService } from "@/api/en/index";
import en from "@/mixins/en/en"; // 公共方法
import viewBacktop from "./readComm/viewBacktop";
import viewRemind from "./readComm/viewRemind";
import viewAddMore from "./readComm/viewAddMore";
import viewBtnOperation from "./readComm/viewBtnOperation";

export default {
  name: "myReceipt",
  components: {
    viewBacktop,
    viewRemind,
    viewAddMore,
    viewBtnOperation
  },
  mixins: [en],
  data() {
    return {
      myNotice: {},
      noticeList: [], // 展示的 数据
      pageNo: 1, // 页码
      pageSize: 10, // 每页的 数量
      color: {
        "000": ["#4fd2c2", "rgba(70,148,223,0.1)"],
        "001": ["#179eda", "rgba(70,148,223,0.1)"],
        "002": ["#fd9a4a", "rgba(70,148,223,0.1)"],
        "006": ["#49bef2", "rgba(70,148,223,0.1)"],
        "004": ["#606060", "rgba(99,108,120,0.1)"],
        "003": ["#606060", "rgba(99,108,120,0.1)"],
        "007": ["#606060", "rgba(99,108,120,0.1)"],
        "008": ["#49bef2", "rgba(70,148,223,0.1)"]
      }, // 第一项是 字体颜色 第二项是 背景颜色
      billStatus: {
        "000": "未提交",
        "001": "待处理",
        "002": "审批中",
        "006": "已审批",
        "004": "已终止",
        "003": "已结束",
        "007": "已作废",
        "008": "已提交"
      },
      receiptFlg: "0", // 默认是待回执
      options: [
        { value: "2", label: "全部回执" },
        { value: "0", label: "待回执" }
      ],
      // 是否显示无数据
      noData: false,
      // 标记是否显示 消息的详情标识
      noticeType: {
        type019: { flg: false, content: "" },
        type048: { flg: false, content: {} },
        remind: { flg: false, content: {} },
        btnOperation: { flg: false, content: {} }
      }
    };
  },
  mounted() {
    this.queryUserConfig();
    this.getList();
  },
  methods: {
    // 查询用户习惯是 全部 还是 未读
    async queryUserConfig() {
      // console.log(this.$store.getters.userInfo);
      this.receiptFlg = this.$store.getters.userInfo.keyReceipt || "0";
    },
    // 下拉 切换
    async change() {
      this.pageNo = 1;
      this.noticeList = [];
      // 切换搜索 清空
      //  保存操作 习惯 再条用查询接口
      await this.saveUserConfig();
      this.getList();
    },
    async saveUserConfig() {
      const params = {
        configKey: "keyReceipt",
        configValue: this.receiptFlg
      };
      this.$store.getters.userInfo.keyReceipt = this.receiptFlg;
      enService.saveUserConfig(params);
    },
    // 查询 我的回执数据
    async getList(flg) {
      if (flg) {
        // flg为true为加载更多不loading
      } else {
        this.loading = true;
      }
      const p = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        readFlag: "002",
        receipt: this.receiptFlg
      };
      let rspData;
      try {
        rspData = await enService.queryReceiptList(p);
      } catch (error) {
        rspData = { records: [] };
      }
      this.myNotice = rspData;
      this.noticeList.push(...rspData.records);
      // 判断是否无数据 避免视图闪现无数据
      if (this.noticeList.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.loading = false;
    },
    message() {
      if (this.receiptFlg === "2") {
        return `回执列表（${this.myNotice.commParams.receipt0}/${this.myNotice.commParams.totalNum}）`;
      }
      return `回执列表（${this.myNotice.commParams.receipt0}）`;
    },
    async modClick(item) {
      console.log("输出点击项", item);
      // 未读变为已读
      if (item.readFlag === "000" && item.type !== "099") await this.read(item);
      // 操作处理
      let length = 0;
      try {
        length = item.buttonKey.length;
      } catch (error) {
        length = 0;
      }
      if (length > 0) {
        // 有操作按钮
        this.noticeType.btnOperation.content = item;
        this.noticeType.btnOperation.flg = true;
      } else {
        // 放在 mixins里面
        this.messageOperation(item);
      }
    },
    // 阅读item 无item标识一键阅读
    async read(item) {
      // 单个操作
      const params = {
        ids: item.id,
        isBatch: "1"
      };
      const rspData = await enService.readNotice(params);
      if (rspData.successNum === 1) {
        item.readFlag = "001";
      }
    },
    // 回执
    async receipt(item) {
      if (item) {
        // 单个操作
        const params = {
          isBatch: "0",
          id: item.id
        };
        await enService.receiptOperate(params);
        item.receipt = "1";
      } else {
        // 批量操作
        if (this.myNotice.commParams.receipt0 === 0) {
          this.$message({
            showClose: true,
            message: "没有可处理的待回执通知",
            type: "success"
          });
          return;
        }
        this.myNotice.commParams.receipt0;
        const params = {
          isBatch: "1"
        };
        await enService.receiptOperate(params);
        const str = `批量处理：成功${this.myNotice.commParams.receipt0}条!`;
        this.$message({
          showClose: true,
          message: str,
          type: "success"
        });
        this.pageNo = 1;
        this.noticeList = [];
        this.getList();
      }
    },
    // 删除
    async deleteNotice(item, k) {
      const params = {
        id: item.id
      };
      await enService.deleteNotice(params);
      this.noticeList.splice(k, 1);
      this.$message({
        message: "操作成功！",
        type: "success"
      });
    },
    // 稍后提醒
    remind(item) {
      this.noticeType.remind.flg = true;
      this.noticeType.remind.content = item;
    },
    // 弹框返回事件
    toget(item) {
      this.noticeType[item].flg = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.msg-remind::before {
  display: inline-block;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background: #ff6432;
  border-radius: 50%;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}

// 最外层
.my-enlis {
  position: relative;
  width: 100%;
  height: 100%;
  .ybox-mod {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    height: 100%;
    .card-define-head{
      padding:5px 20px;
    }
    // 标题
    .ybox-title {
      color: #333;
      font-size: 14px;
      line-height: 22px;
      zoom: 1;
      height: auto;
      // border-bottom: 1px solid #ecf1f7;
      padding: 5px 15px;
      overflow: hidden;

      .pull-left {
        float: left !important;
        font-size: 14px;
        line-height: 40px;
        // font-family: "PingFangSC-Light", "Hiragino Sans GB", Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
        font-weight: bold;
      }

      .pull-right {
        float: right !important;
      }
    }

    // 内容
    .ybox-content {
      padding: 0;
      zoom: 1;
      height: calc(100% - 52px);
      .enlis {
        height: calc(100% - 46px);
        overflow-y: auto;
        font-size: 12px;
        padding-bottom: 0;
        zoom: 1;
        scroll-behavior: smooth; //平滑滚动
        padding-left: 10px;
        padding-right: 10px;
        //单个设置
        .mod:last-child {
          border-bottom: 0;
        }

        .mod {
          padding: 14px 2% 0;
          padding-left:70px;
          line-height: 24px;
          color: #606060;
          cursor: pointer;
          zoom: 1;
          background: rgba(232, 236, 242, 0.25);
          border-radius: 4px;
          margin-bottom: 8px;
          position: relative;
          // min-height: 72px;
          min-height: 80px;
          // border-bottom: 1px solid #f3f3f3;

          .col {
            min-height: 24px;

            p {
              display: inline-block;
              margin: 0;
            }
          }

          // 图标
          .col-icon {
            min-height: 24px;
            position: absolute;
            left: 20px;
            top: 15px;
            width: 40px;

            .iconfont {
              background-color: #3e90fe;
              width: 40px;
              height: 40px;
              color: #fff;
              border-radius: 50%;
              line-height: 40px;
              text-align: center;
              display: block;
              margin: 0;
              font-size: 22px;
              font-style: normal;
            }
          }

          // 左侧
          .pull-left {
            float: left !important;
            text-align: left;
            width: 70%;
            .col-num {
              display: inline-block;
              vertical-align: top;
            }

            .col-tit {
            }
          }

          // 右侧
          .pull-right {
            float: right !important;
            text-align: right;
            width: 20%;
            .col-time{
              color: #91A1B7;
            }
            .span-btn {
              display: none;
            }
          }
        }
        .mod:after {
          content: "";
          display: block;
          clear: both;
        }
        .mod:hover {
          // background: #F5F9FD;
          background: rgba(162, 205, 247, 0.25);
          cursor: pointer;
          .span-btn {
            display: inline;
          }
        }
      }
    }
  }
}
// 一键阅读
.piliangchuli-weidu {
  color: #bebebe;
  line-height: 35px;
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
}
.receipt {
  .pull-left {
    opacity: 0.5;
  }
  .col-icon {
    opacity: 0.5;
  }
  .col-time {
    opacity: 0.5;
  }
}
</style>
